import { gql, useMutation } from '@apollo/client'
import { noop } from 'functions'
import { useBreakpoints } from 'hooks'
import { usePathname } from 'next/navigation'
import { useCallback } from 'react'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import {
  type EventType,
  type TrackEventInput,
  type TrackEventMutation,
  type TrackEventMutationVariables,
} from '~/types/graphql'
import { browserSupportsVideocall } from '~/utils/minimumRequirements'

const MUTATION = gql`
  mutation trackEvent($input: TrackEventInput!) {
    trackEvent(input: $input) {
      ok
    }
  }
`

type Param = {
  name: string
  payload?: TrackEventInput['payload']
}

export const useTrackEvent = (type: EventType) => {
  const [trackEvent] = useMutation<TrackEventMutation, TrackEventMutationVariables>(MUTATION)

  const pathname = usePathname()

  const isNativeApp = useIsNativeApp()
  const { isExtraLarge, isLargeOnly, isMediumOnly, isSmallOnly } = useBreakpoints()

  const user = useCurrentUserNullable()
  const groups = useUserActingAsGroup()

  const isAnonymous = user.id == null
  const userGroup = groups.includes('therapist') ? 'THERAPIST' : 'PATIENT'

  return useCallback(
    ({ name, payload }: Param) => {
      trackEvent({
        variables: {
          input: {
            name,
            type,
            payload,
            breakpoints: { isExtraLarge, isLargeOnly, isMediumOnly, isSmallOnly },
            clientType: isNativeApp ? 'NATIVE_APP' : 'WEB',
            requirements: {
              vih: browserSupportsVideocall,
            },
            url: {
              pathname,
            },
            userType: isAnonymous ? 'ANONYMOUS' : userGroup,
          },
        },
      }).catch(noop)
    },
    [
      isAnonymous,
      isExtraLarge,
      isLargeOnly,
      isMediumOnly,
      isNativeApp,
      isSmallOnly,
      pathname,
      trackEvent,
      type,
      userGroup,
    ],
  )
}
