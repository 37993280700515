import { Flex } from 'cdk'
import { OPACITY_0, OPACITY_100, SPACING_SM } from 'design-tokens'
import { Icon, QuestionMarkCircle } from 'icons'
import { memo, useCallback } from 'react'
import { Link, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { useTabNavigationMenu } from '~/domains/navigation/NewNavigation'
import { FlexWithTransition } from '../common/FlexWithTransition'
import { NavLinkContainer } from '../common/NavLinkContainer'

type Props = {
  extended: boolean
  isMobile?: boolean
  onLinkClick?: () => void
}

export const LinkSupport = memo(({ extended, onLinkClick, isMobile = false }: Props) => {
  const { open } = useIntercom()
  const { closeMenu } = useTabNavigationMenu()

  const handleClick = useCallback(() => {
    open('home')
    open('help')
    closeMenu()
    onLinkClick?.()
  }, [closeMenu, onLinkClick, open])

  return (
    <Link onClick={handleClick}>
      <NavLinkContainer $direction="row" $gap={SPACING_SM}>
        <Flex>
          <Icon Svg={QuestionMarkCircle} colorName="neutral-110" fillColorName="lighter" size={24} />
        </Flex>
        {!isMobile && (
          <FlexWithTransition $opacity={extended ? OPACITY_100 : OPACITY_0}>
            <Text colorName="neutral-110" kind="paragraph">
              <Translation id="menu.help.support" />
            </Text>
          </FlexWithTransition>
        )}
      </NavLinkContainer>
    </Link>
  )
})
