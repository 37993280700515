import { useRouter } from 'next/navigation'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getRoute, type Route } from '~/utils/getRoute'

export const useUserRedirectEffect = (redirect: boolean, target: Route = '/', withNextJsRouting = false) => {
  const history = useHistory()
  const router = useRouter()

  useEffect(() => {
    if (redirect) {
      if (withNextJsRouting) {
        router.replace(target)
        return
      }

      history.replace(getRoute(target))
    }
  }, [history, redirect, target, router, withNextJsRouting])
}
