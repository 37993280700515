import { type ComponentProps } from 'react'
import { Controller, type RegisterOptions, useFormContext } from 'react-hook-form'
import { Switch } from 'ui-deprecated'
import { useLoadingState } from '~/hooks/useLoadingState'

type SwitchProps = ComponentProps<typeof Switch>

type Props = SwitchProps & {
  rules?: RegisterOptions
  onChangeExternal?: () => void
}

export const ReactHookFormSwitchField = ({ disabled, name, onChangeExternal, rules, ...props }: Props) => {
  const { control } = useFormContext()
  const { loading } = useLoadingState()

  return (
    <Controller
      control={control}
      defaultValue={false}
      name={name}
      render={({
        field: { onChange, value, ref },
        fieldState: { invalid },
        formState: { isSubmitting, isSubmitted, isValid },
      }) => (
        <Switch
          {...props}
          ref={ref}
          checked={value}
          disabled={disabled || (isValid && (isSubmitting || loading))}
          invalid={invalid && isSubmitted}
          name={name}
          onChange={(event) => {
            if (onChangeExternal != null) {
              onChangeExternal()
            }
            onChange(event)
          }}
          value={name}
        />
      )}
      rules={rules}
    />
  )
}
