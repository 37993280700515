import { Flex } from 'cdk'
import { modalContainerDesktop, modalContainerMedium, modalContainerMobile } from '../constants/containers'

/** @deprecated Use `Modal` from `ui` */
export const Modals = () => (
  <>
    <Flex $hide $mdHide={false} id={modalContainerDesktop} />

    <Flex $mdHide id={modalContainerMobile} />

    <Flex $lgHide id={modalContainerMedium} />
  </>
)
