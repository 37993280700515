import { Flex } from 'cdk'
import { OPACITY_0, OPACITY_100, SPACING_SM } from 'design-tokens'
import { Icon, SerenisNotification } from 'icons'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { getRoute } from '~/utils/getRoute'
import { type NavigationProps } from '../../types'
import { FlexWithTransition } from '../common/FlexWithTransition'
import { NavLinkContainer } from '../common/NavLinkContainer'

type Props = {
  onLinkClick?: () => void
} & NavigationProps

export const LinkNotification = ({ extended, onLinkClick, isMobile = false }: Props) => {
  const { pathname } = useLocation()
  const active = !!matchPath(pathname, {
    path: [getRoute('/communications')],
    exact: true,
  })

  return (
    <Link onClick={onLinkClick} to={getRoute('/communications')}>
      <NavLinkContainer $direction="row" $gap={SPACING_SM}>
        <Flex>
          <Icon
            Svg={SerenisNotification}
            colorName={active ? 'primary-50' : 'neutral-110'}
            fillColorName="lighter"
            size={24}
          />
        </Flex>
        {!isMobile && (
          <FlexWithTransition $opacity={extended ? OPACITY_100 : OPACITY_0}>
            <Text colorName={active ? 'primary-50' : 'neutral-110'} kind={active ? 'paragraph-strong' : 'paragraph'}>
              <Translation id="menu.notification" />
            </Text>
          </FlexWithTransition>
        )}
      </NavLinkContainer>
    </Link>
  )
}
