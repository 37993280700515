import { Flex } from 'cdk'
import { OPACITY_0, OPACITY_100, SPACING_SM } from 'design-tokens'
import { GestoLogoIcon } from 'illustrations'
import { Link, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { getEnv } from '~/utils/getEnv'
import { type NavigationProps } from '../../types'
import { FlexWithTransition } from '../common/FlexWithTransition'
import { NavLinkContainer } from '../common/NavLinkContainer'

type Props = {
  onLinkClick?: () => void
} & NavigationProps

export const LinkGesto = ({ extended, onLinkClick, isMobile = false }: Props) => {
  const gestoAppUrl = getEnv('GESTO_APP_URL')

  return (
    <Link href={`${gestoAppUrl}/dashboard`} onClick={onLinkClick} target="_blank">
      <NavLinkContainer $direction="row" $gap={SPACING_SM} $minHeight={40}>
        <Flex>
          <GestoLogoIcon />
        </Flex>
        {!isMobile && (
          <FlexWithTransition $opacity={extended ? OPACITY_100 : OPACITY_0}>
            <Text colorName="neutral-110" kind="paragraph">
              <Translation id="menu.gesto" />
            </Text>
          </FlexWithTransition>
        )}
      </NavLinkContainer>
    </Link>
  )
}
