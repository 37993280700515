import { useCallback } from 'react'
import { useTrackEvent } from './useTrackEvent'

// NOTE(@heavybeard): New event for new application should be added here
type EventClickIdV2 =
  | 'conventions-and-prices.try-adding-convention'
  | 'legal-guardians.open-detail'
  | 'legal-guardians.try-adding-legal-guardian'
  | 'legal-guardians.try-editing-legal-guardian'
  | 'paths.show-more-therapy-sessions'
  | 'paths.show-next-therapy'
  | 'paths.show-prev-therapy'
  | 'paths.show-specific-therapy'
  | 'paths.start-creating-therapy'
  | 'professional.view-curriculum'
  | 'therapy-path.open-detail'
  | 'therapy-path.try-changing-professional'
  | 'therapy-path.go-to-professional-chat'
  | 'therapy-session.close-detail'
  | 'therapy-session.go-to-adding-payment-method'
  | 'therapy-session.go-to-videocall'
  | 'therapy-session.manage'
  | 'therapy-session.open-detail'
  | 'therapy-session.pay'
  | 'therapy-session.start-booking-new'
  | 'therapy-session.go-to-professional-chat'
  | 'therapy-session.go-to-professional-chat-for-booking'
  | 'therapy-session.go-to-professional-chat-for-rescheduling'
  | 'therapy-session.go-to-professional-chat-for-rescheduling-after-cancellation-policy-limit'
  | 'therapy.try-creating-new'

export type EventClickId =
  | 'agenda.download-clinical-bond-response'
  | 'agenda.download-form-flow-response'
  | 'booking.calendar-day'
  | 'booking.calendar-hour'
  | 'clinical-test.chat.patient.read-answers'
  | 'clinical-test.chat.patient.start'
  | 'clinical-test.chat.therapist.read-answers'
  | 'clinical-test.exit-confirmation-modal.cancel-closing'
  | 'clinical-test.exit-confirmation-modal.return-to-chat'
  | 'clinical-test.send-to-patient-chat.send'
  | 'clinical-test.step.end.return-to-chat'
  | 'clinical-test.step.intro.start'
  | 'clinical-test.step.question.next'
  | 'clinical-test.step.question.previous'
  | 'clinical-test.step.question.send'
  | 'invite.share.mobile'
  | 'invite.share.desktop'
  | 'pre-booking-wish-booking'
  | 'pre-booking-wish-calendar'
  | 'pre-booking-wish-comparing'
  | 'pre-booking-wish-curriculum'
  | 'request-nutritionist'
  | 'reservation.calendar-day'
  | 'reservation.calendar-hour'
  | 'reservation.hardcoded-unavailability-selected'
  | 'settings-invite.from-mgm-card'
  | 'settings-invite.from-mgm-icon'
  | 'settings-invite.from-menu'
  | 'share.journaling-reward'
  | 'share.journaling-score'
  | 'share.sentence'
  | 'share.sticker'
  | 'share.therapy-session'
  | 'support.i-cant-find-slot'
  | 'support.i-want-to-change-therapis'
  | 'support.i-want-to-edit-therapy-session'
  | 'support.leave-feedback'
  | 'support.open-intercom'
  | 'thank-you-page.download-calendar-event'
  | 'thank-you-page.download-app'
  | 'thank-you-page.open-chat'
  | 'thank-you-page.go-to-homepage'
  | EventClickIdV2

type Payload = Parameters<ReturnType<typeof useTrackEvent>>[number]['payload']

export const useTrackEventClick = () => {
  const trackEvent = useTrackEvent('CLICK')

  return useCallback(
    (id: EventClickId, payload?: Payload) => {
      trackEvent({ name: `clicks.${id}`, payload })
    },
    [trackEvent],
  )
}
